import React from "react"
// import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { Container } from "reactstrap"
import {
  Layout,
  // DisplayH4,
  // HR,
  GeneralServices,
  AdditionalServices,
  TakeALook,
  CompletedSection,
  OngoingSection,
  SEO
} from "~/components"
import ServiceProjectBanner from "~/components/Images/ServiceProjectBanner"

const ServicesProject = () => {
  return (
    <Layout>
      <SEO
        title="Services and Projects"
        description="DGO Construction - We always ascertain of carrying out satisfiable and quality output from the work that we provide. Take a look at our projects."
      />
      <SPSection>
        <BannerArea>
          <Banner>
            <ServiceProjectBanner />
            <Container>
              <Title>SERVICES AND PROJECTS</Title>
            </Container>
          </Banner>
        </BannerArea>
        <ServiceProjectComponent>
          <GeneralServices />
          <AdditionalServices buttonAreaDisplay={false} />
          <TakeALook />
        </ServiceProjectComponent>
        <CompletedSection />
        <OngoingSection />
      </SPSection>
    </Layout>
  )
}

export default ServicesProject

const SPSection = styled.section`
  text-align: center;
`

const BannerArea = styled.div`
  height: 250px;
  position: relative;
`

const Banner = styled.div`
  position: absolute;
  width: 100%;
  & > div {
    height: 250px;
  }
`

const Title = styled.h1`
  top: 125px;
  position: absolute;
  color: #fff;
`

const ServiceProjectComponent = styled.div`
  padding: 80px 0;
  p {
    color: #2c2c2c;
  }
  strong {
    font-size: 22px;
    line-height: 24px;
    display: block;
    margin: 10px 0;
  }
`
